/* html */
@font-face { 
    font-family: GTAmericaExtendedMedium; src: url('./fonts/GT-America-Extended-Medium.otf'); 
} 
@font-face { 
    font-family: GTAmericaExtendedRegular; src: url('./fonts/GT-America-Extended-Regular.otf'); } 

.typo {
    font-size: 16px;
    font-family: GTAmericaExtendedRegular, sans-serif;
}

ul {
    padding: 0;
}

a {
    color: #000000;
    font-size: 16px;
    font-family: GTAmericaExtendedRegular, sans-serif;
}
a:hover {
    color: black
}

#blue {
    font-family: GTAmericaExtendedRegular, sans-serif;     font-size: 38px;
}
#green,
#pink,
#yellow,
#red,
#blau,
#blue
{ font-family: GTAmericaExtendedRegular, sans-serif; font-size: 38px}

/* colors */
#blue:hover {
    color: #5233FF;
}
#green:hover {
    color: #00FF89;
}
#pink:hover {
    color: #FF36A2;
}
#blau:hover {
    color: #1FABFF
}
#yellow:hover {
    color: #FFF723;
}
#red:hover {
    color: #FF2389;
}

#friedel:hover {
    color: #5233FF;
}
#book:hover {
    color: #00FF89;
}
#info:hover {
    color: #FF36A2;
}
#tumblr:hover {
    color: #FFF723;
}
#projects:hover {
    color: #FFF723;
}

.bull {
    padding: 1rem
}


/* header */
header {
    /*height: 5vh;*/
}

/* main */


main {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.top {
    list-style: none;
    margin: 0;
    line-height: 2.5rem;
}
.top > li {
    display: inline;
    text-decoration: none;
}
.top > li > a{
    text-decoration: none;
    font-size: 3rem;
}
.top > li > p{
    display: inline;
    font-size: 3rem;
}


/* footer */
footer {
    /*height: 5vh;*/
}
footer * {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 97vh
}
.content {
    height: 70vh;
}
.sliderImages {
    height: 70vh;
    width: auto;
}

.eclat {
    background-image: url(./home/eclat.jpg);
}
.sunshine {
    background-image: url(./home/sunshine.jpg);
}
.paradise {
    background-image: url(./home/paradise.jpg);
}
.passiflora {
    background-image: url(./home/passiflora.jpg);
}
.jamon {
    background-image: url(./home/jamon.jpg);
}
.sieben {
    background-image: url(./home/sieben.jpg);
}
.wurst {
    background-image: url(./home/wurst.jpg);
}


#content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#content ul li a, #content ul li p {
    color: #474747;
}

.cv {

}

.mail-td {
    cursor: pointer;
}

/*td:first-child { */
/*    width: 170px;    */
/*    vertical-align: top;*/
/*}*/

td {
    width: 32vw;
}

td.info-content-column {
    width: 50vw;
}

td.info-year-column {
    width: 14vw;
}

.navi {
    position: absolute;
    width: 50%;
    height: 100%;
    cursor: pointer;

}

.prev {
    position: absolute;
    left: 0;
    cursor: pointer; /*url("/prev.png"), pointer;*/
}
.next {
    position: absolute;
    right: 0;
    cursor: pointer; /*url("/next.png"), pointer;*/

}

.prev svg {
    position: absolute;
    left: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #ddd;
    font-size: 2em;
}

.prev:hover svg {
    color: #777;
}

.next svg {
    position: absolute;
    right: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #ddd;
    font-size: 2em;
}

.next:hover svg {
    color: #777;
}

#root .infolinks {
    font-size: 1em;
}

#root .infolinks:hover {
    text-decoration: none;
}

.copyright {
    position: absolute;
    bottom: -16px;
    width: 71px;
    font-size: 10px;
    right: 0;

}

@media (max-width: 1300px) {

    #green,
    #pink,
    #yellow,
    #red,
    #blue,
    #blau
    { font-size: 28px}

    .top > li > p{
        font-size: 2rem;
    }

    .bull {
        padding: 0.6rem
    }
}

@media (max-width: 960px) {
    #green,
    #pink,
    #yellow,
    #red,
    #blue,
    #blau
    { font-size: 20px}

    .top > li > p{
        font-size: 1.5rem;
    }

    .bull {
        padding: 0.4rem
    }
}

@media (max-width: 900px) {
    .sliderImages {
        width: 100%;
        height: auto;
    }

    .slider {
        align-items: flex-start;
    }
}

@media (max-width: 768px) {
    .navbar {
        padding: 0.5rem 0;
    }
    .navbar-expand .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-expand .navbar-nav.ml-auto .nav-link {
        padding-left: 0.5rem;
        padding-right: 0;
    }

    #green,
    #pink,
    #yellow,
    #red,
    #blue,
    #blau
    { font-size: 17px}

    .top > li > p{
        font-size: 1rem;
    }

    .bull {
        padding: 0.3rem
    }
}